export const CARS_LIST_REQUEST = 'CARS_LIST_REQUEST';
export const CARS_LIST_SUCCESS = 'CARS_LIST_SUCCESS';

export const CAR_DETAILS_REQUEST = 'CAR_DETAILS_REQUEST';
export const CAR_DETAILS_SUCCESS = 'CAR_DETAILS_SUCCESS';

export const CAR_ADD_REQUEST = 'CAR_ADD_REQUEST';
export const CAR_ADD_SUCCESS = 'CAR_ADD_SUCCESS';

export const CAR_SAVE_REQUEST = 'CAR_SAVE_REQUEST';
export const CAR_SAVE_SUCCESS = 'CAR_SAVE_SUCCESS';

export const CAR_PURGE = 'CAR_PURGE';

export const CARS_FILTERS_CHANGE = 'CARS_FILTERS_CHANGE';
export const CARS_FILTERS_RESET = 'CARS_FILTERS_RESET';
