export const OWNERS_LIST_REQUEST = 'OWNERS_LIST_REQUEST';
export const OWNERS_LIST_SUCCESS = 'OWNERS_LIST_SUCCESS';

export const OWNER_DETAILS_REQUEST = 'OWNER_DETAILS_REQUEST';
export const OWNER_DETAILS_SUCCESS = 'OWNER_DETAILS_SUCCESS';
export const OWNER_DETAILS_PURGE = 'OWNER_DETAILS_PURGE';

export const OWNER_ADD_REQUEST = 'OWNER_ADD_REQUEST';
export const OWNER_ADD_SUCCESS = 'OWNER_ADD_SUCCESS';

export const OWNER_SAVE_REQUEST = 'OWNER_SAVE_REQUEST';
export const OWNER_SAVE_SUCCESS = 'OWNER_SAVE_SUCCESS';

export const OWNERS_CHANGE_FILTERS = 'OWNERS_CHANGE_FILTERS';
