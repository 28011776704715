import Vue from 'vue';

import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/ru-RU';
import locale from 'element-ui/lib/locale';
import axios from 'axios';

import VueTheMask from 'vue-the-mask';

import App from '@/App';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';

import '@/services/filters';

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/main.scss';

Vue.use(VueTheMask);

Vue.config.productionTip = false;

Vue.use(ElementUI);
locale.use(lang);

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'https://dev.garagecrm.ru/api' : 'https://dev.garagecrm.ru/api';

if (localStorage.getItem('garage-secret')) {
  axios.defaults.headers.common.Authorization = `Bearer ${JSON.parse(localStorage.getItem('garage-secret'))?.access}`;
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
