import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './modules/auth';
import Users from './modules/users';
import Company from './modules/company';
import Cars from './modules/cars';
import Owners from './modules/owners';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Users,
    Auth,
    Company,
    Cars,
    Owners,
  },
});
