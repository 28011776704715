import axios from 'axios';

import {
  CARS_LIST_SUCCESS, CARS_LIST_REQUEST, CARS_FILTERS_CHANGE,
  CAR_DETAILS_REQUEST, CAR_DETAILS_SUCCESS, CAR_SAVE_REQUEST,
  CAR_SAVE_SUCCESS, CAR_ADD_REQUEST, CAR_ADD_SUCCESS, CAR_PURGE,
  CARS_FILTERS_RESET,
} from '@/store/actions/cars';
import Qs from 'qs';
import paramsFilter from '@/services/paramsFilter';
import { OWNER_DETAILS_REQUEST } from '@/store/actions/owners';

function Filters() {
  this.search = '';
  this.limit = 10;
  this.offset = 1;
}

const state = {
  cars: {
    items: [],
    clientsSuggestList: [],
    count: 1,
    loading: false,
  },
  carDetails: {
    car: null,
    loading: false,
    saveLoading: false,
  },
  filters: new Filters(),
};

const getters = {
  cars: () => state.cars,
  carsFilters: () => state.filters,
  carDetails: () => state.carDetails,
};

const actions = {
  [CARS_LIST_REQUEST]({ commit }) {
    commit(CARS_LIST_REQUEST);

    return axios.get('/cars/',
      {
        params: state.filters,
        paramsSerializer(params) {
          return Qs.stringify(params, {
            arrayFormat: 'repeat',
            filter: paramsFilter,
          }).replace(/%3A/g, ':');
        },
      })
      .then((resp) => {
        commit(CARS_LIST_SUCCESS, resp.data);
        console.log(resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(CARS_LIST_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [CAR_DETAILS_REQUEST]({ commit, dispatch }, id) {
    commit(CAR_DETAILS_REQUEST);

    return axios.get(`/cars/${id}/`)
      .then((resp) => {
        commit(CAR_DETAILS_SUCCESS, resp.data);

        if (resp.data?.owner?.id) {
          dispatch(OWNER_DETAILS_REQUEST, resp.data.owner.id);
        }

        console.log(222, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(CAR_DETAILS_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [CAR_ADD_REQUEST]({ commit }, car) {
    commit(CAR_ADD_REQUEST);

    return axios.post('/cars/', car)
      .then((resp) => {
        commit(CAR_ADD_SUCCESS, resp.data);
        console.log(222, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(CAR_ADD_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [CAR_SAVE_REQUEST]({ commit }, car) {
    commit(CAR_SAVE_REQUEST);

    return axios.patch(`/cars/${car.id}/`, car)
      .then((resp) => {
        commit(CAR_SAVE_SUCCESS, resp.data);
        console.log(222, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(CAR_SAVE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [CARS_FILTERS_CHANGE]({ commit, dispatch }, filters) {
    commit(CARS_FILTERS_CHANGE, filters);
    dispatch(CARS_LIST_REQUEST);
  },
};

const mutations = {
  [CARS_LIST_REQUEST]: (state) => {
    state.cars.loading = !state.cars.loading;
  },
  [CARS_LIST_SUCCESS]: (state, resp) => {
    state.cars.count = resp.count;
    state.cars.items = resp.results;
    state.cars.loading = false;
  },

  [CAR_DETAILS_REQUEST]: (state) => {
    state.carDetails.loading = !state.carDetails.loading;
  },
  [CAR_DETAILS_SUCCESS]: (state, resp) => {
    state.carDetails.car = resp;
    state.carDetails.loading = false;
  },

  [CAR_ADD_REQUEST]: (state) => {
    state.carDetails.saveLoading = !state.carDetails.saveLoading;
  },
  [CAR_ADD_SUCCESS]: (state, resp) => {
    state.carDetails.car = resp;
    state.carDetails.saveLoading = false;
  },

  [CAR_SAVE_REQUEST]: (state) => {
    state.carDetails.saveLoading = !state.carDetails.saveLoading;
  },
  [CAR_SAVE_SUCCESS]: (state, resp) => {
    state.carDetails.car = resp;
    state.carDetails.saveLoading = false;
  },

  [CARS_FILTERS_CHANGE]: (state, filters) => {
    state.filters = filters;
  },
  [CARS_FILTERS_RESET]: (state) => {
    state.filters = new Filters();
  },

  [CAR_PURGE]: (state) => {
    state.carDetails.car = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
