// eslint-disable no-shadow
import axios from 'axios';
import {
  AUTH_TOKEN_REQUEST,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_REFRESH_TOKEN_REQUEST,
  AUTH_ERROR,
  AUTH_TOKEN_SUCCESS,
} from '../actions/auth';

const state = {
  authData: {
    isAuthenticated: !!JSON.parse(localStorage.getItem('garage-secret'))?.access,
    loading: false,
    error: false,
  },
};

const getters = {
  authData: () => state.authData,
};

const actions = {
  [AUTH_TOKEN_REQUEST]({ commit, dispatch }, user) {
    commit(AUTH_TOKEN_REQUEST);
    return axios.post('/token/', user)
      .then((resp) => {
        commit(AUTH_TOKEN_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(AUTH_ERROR);
        localStorage.removeItem('garage-secret');
        return Promise.reject(err.response.data);
      });
  },
  [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve) => {
    localStorage.removeItem('garage-secret');
    delete axios.defaults.headers.common.Authorization;
    commit(AUTH_LOGOUT);
    resolve();
  }),
};

const mutations = {
  [AUTH_TOKEN_REQUEST]: (state) => {
    state.authData.loading = true;
    state.authData.error = false;
  },
  [AUTH_TOKEN_SUCCESS]: (state, resp) => {
    state.authData.loading = false;
    state.authData.error = false;
    localStorage.setItem('garage-secret', JSON.stringify(resp));
    axios.defaults.headers.common.Authorization = `Bearer ${resp.access}`;
    state.authData.isAuthenticated = true;
  },
  [AUTH_ERROR]: (state) => {
    state.authData.loading = false;
    state.authData.error = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.authData.isAuthenticated = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
