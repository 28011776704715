import axios from 'axios';

import {
  USERS_LIST_REQUEST, USERS_LIST_SUCCESS, USER_REQUEST, USER_SUCCESS, USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS, USER_NEW_ADD_SUCCESS, USER_NEW_ADD_REQUEST, USER_DELETE_REQUEST, USER_DELETE_SUCCESS,
} from '../actions/users';

const state = {
  usersList: {
    list: [],
    loading: false,
  },
  userDetails: {
    user: null,
    loading: false,
    saveLoading: false,
  },
};

const getters = {
  usersList: () => state.usersList,
  userDetails: () => state.userDetails,
};

const actions = {
  [USERS_LIST_REQUEST]({ commit }) {
    commit(USERS_LIST_REQUEST);
    return axios.get('/users/?limit=1000&page=0')
      .then((resp) => {
        commit(USERS_LIST_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(USERS_LIST_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [USER_NEW_ADD_REQUEST]({ commit, dispatch }, user) {
    commit(USER_NEW_ADD_REQUEST);
    return axios.post('/users/', user)
      .then((resp) => {
        commit(USER_NEW_ADD_SUCCESS, resp.data);
        dispatch(USERS_LIST_REQUEST);
        return resp.data;
      })
      .catch((err) => {
        commit(USER_NEW_ADD_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [USER_SAVE_REQUEST]({ commit, dispatch }, user) {
    commit(USER_SAVE_REQUEST);
    return axios.patch(`/users/${user.id}/`, user)
      .then((resp) => {
        commit(USER_SAVE_SUCCESS, resp.data);
        dispatch(USERS_LIST_REQUEST);
        return resp.data;
      })
      .catch((err) => {
        commit(USER_SAVE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
};

const mutations = {
  [USERS_LIST_REQUEST]: (state) => {
    state.usersList.loading = !state.usersList.loading;
  },
  [USERS_LIST_SUCCESS]: (state, resp) => {
    state.usersList.loading = false;
    state.usersList.list = resp;
  },

  [USER_NEW_ADD_REQUEST]: (state) => {
    state.userDetails.saveLoading = !state.userDetails.saveLoading;
  },
  [USER_NEW_ADD_SUCCESS]: (state) => {
    state.userDetails.saveLoading = false;
  },

  [USER_SAVE_REQUEST]: (state) => {
    state.userDetails.saveLoading = !state.userDetails.saveLoading;
  },
  [USER_SAVE_SUCCESS]: (state) => {
    state.userDetails.saveLoading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
