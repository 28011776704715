import axios from 'axios';

import {
  OWNERS_LIST_REQUEST, OWNERS_LIST_SUCCESS, OWNER_ADD_SUCCESS,
  OWNER_DETAILS_SUCCESS, OWNER_SAVE_REQUEST, OWNER_ADD_REQUEST,
  OWNER_SAVE_SUCCESS, OWNER_DETAILS_REQUEST, OWNER_DETAILS_PURGE,
  OWNERS_CHANGE_FILTERS,
} from '@/store/actions/owners';
import Qs from 'qs';
import paramsFilter from '@/services/paramsFilter';

function Filters() {
  this.search = '';
  this.limit = 20;
  this.offset = 1;
}

const state = {
  owners: {
    items: [],
    count: 1,
    loading: false,
  },
  ownerDetails: {
    owner: null,
    loading: false,
    saveLoading: false,
  },
  filters: new Filters(),
};

const getters = {
  owners: () => state.owners,
  ownerDetails: () => state.ownerDetails,
  ownersFilters: () => state.filters,
};

const actions = {
  [OWNERS_LIST_REQUEST]({ commit }) {
    commit(OWNERS_LIST_REQUEST);

    return axios.get('/owners/',
      {
        params: state.filters,
        paramsSerializer(params) {
          return Qs.stringify(params, {
            arrayFormat: 'repeat',
            filter: paramsFilter,
          }).replace(/%3A/g, ':');
        },
      })
      .then((resp) => {
        commit(OWNERS_LIST_SUCCESS, resp.data);
        console.log(resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(OWNERS_LIST_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [OWNER_DETAILS_REQUEST]({ commit }, id) {
    commit(OWNER_DETAILS_REQUEST);

    return axios.get(`/owners/${id}/`)
      .then((resp) => {
        commit(OWNER_DETAILS_SUCCESS, resp.data);
        console.log(222, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(OWNER_DETAILS_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [OWNER_ADD_REQUEST]({ commit }, owner) {
    commit(OWNER_ADD_REQUEST);

    return axios.post('/owners/', owner)
      .then((resp) => {
        commit(OWNER_ADD_SUCCESS, resp.data);
        console.log(222, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(OWNER_ADD_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [OWNER_SAVE_REQUEST]({ commit }, owner) {
    commit(OWNER_SAVE_REQUEST);

    return axios.patch(`/owners/${owner.id}/`, owner)
      .then((resp) => {
        commit(OWNER_SAVE_SUCCESS, resp.data);
        console.log(222, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(OWNER_SAVE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [OWNERS_CHANGE_FILTERS]({ commit }, filters) {
    commit(OWNERS_CHANGE_FILTERS, filters);
  },
};

const mutations = {
  [OWNERS_LIST_REQUEST]: (state) => {
    state.owners.loading = !state.owners.loading;
  },
  [OWNERS_LIST_SUCCESS]: (state, resp) => {
    state.owners.count = resp.count;
    state.owners.items = resp.results;
    state.owners.loading = false;
  },

  [OWNER_DETAILS_REQUEST]: (state) => {
    state.ownerDetails.loading = !state.ownerDetails.loading;
  },
  [OWNER_DETAILS_SUCCESS]: (state, resp) => {
    state.ownerDetails.owner = resp;
    state.ownerDetails.loading = false;
  },

  [OWNER_ADD_REQUEST]: (state) => {
    state.ownerDetails.saveLoading = !state.ownerDetails.saveLoading;
  },
  [OWNER_ADD_SUCCESS]: (state, resp) => {
    state.ownerDetails.owner = resp;
    state.ownerDetails.saveLoading = false;
  },

  [OWNER_SAVE_REQUEST]: (state) => {
    state.ownerDetails.saveLoading = !state.ownerDetails.saveLoading;
  },
  [OWNER_SAVE_SUCCESS]: (state, resp) => {
    state.ownerDetails.owner = resp;
    state.ownerDetails.saveLoading = false;
  },

  [OWNERS_CHANGE_FILTERS]: (state, filters) => {
    state.filters = filters;
  },

  [OWNER_DETAILS_PURGE]: (state) => {
    state.ownerDetails.owner = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
