<template>
<div class="container-h">
  <side-bar />
  <div class="content-wrap">
    <slot/>
  </div>
</div>
</template>

<script>
import SideBar from '@/components/side-bar';

export default {
  name: 'Crm',
  components: { SideBar },
};
</script>

<style scoped>

</style>
