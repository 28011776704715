<template>
  <div class="side-bar">
    <div class="side-bar__inner">
      <img src="/img/logo.svg" class="side-bar__logo" alt="logo" />

      <div class="side-bar__menu">
        <router-link :to="item.path" v-for="(item, ind) in routes" :key="ind" class="side-bar__menu-item text_15 text_b text_dark">
          {{item.title}}
        </router-link>
      </div>

      <a class="side-bar__menu-item text_15 text_b mt_auto cp" @click="logout">
        Выход
      </a>
    </div>
  </div>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/actions/auth';

export default {
  name: 'side-bar',
  data() {
    return {
      routes: [],
    };
  },
  created() {
    this.routes = this.$router.options.routes.filter((item) => item.menu).map((item) => item.menu);
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/login');
      });
    },
  },
};
</script>

<style scoped>

</style>
