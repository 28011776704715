import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.authData.isAuthenticated) {
    next();
    return;
  }

  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.authData.isAuthenticated) {
    next();
    return;
  }

  next('/login');
};

const routes = [
  { path: '/', redirect: '/orders' },
  {
    path: '/orders',
    name: 'orders',
    menu: {
      path: '/orders',
      title: 'Заказы',
    },
    component: () => import('@/views/OrdersView'),
  },
  {
    path: '/clients/:id',
    name: 'clientsDetails',
    component: () => import('@/views/Clients/ClientDetailsView'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/clients',
    name: 'clients',
    menu: {
      path: '/clients',
      title: 'Клиенты',
    },
    component: () => import('@/views/Clients/ClientsView'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/company',
    name: 'company',
    menu: {
      path: '/company',
      title: 'Компания',
    },
    component: () => import('@/views/Company/CompanyView'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'WhiteBlanc' },
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView'),
    beforeEnter: ifNotAuthenticated,
  },
  { path: '/', redirect: '/orders' },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
