import axios from 'axios';

import {
  COMPANY_REQUEST, COMPANY_SUCCESS, COMPANY_SAVE_REQUEST, COMPANY_SAVE_SUCCESS,
} from '../actions/company';

const state = {
  company: {
    details: null,
    needAddData: false,
    loading: false,
    saveLoading: false,
  },
};

const getters = {
  company: () => state.company,
};

const actions = {
  [COMPANY_REQUEST]({ commit }) {
    commit(COMPANY_REQUEST);
    return axios.get('/company/')
      .then((resp) => {
        commit(COMPANY_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(COMPANY_REQUEST);
        return Promise.reject(err.response.data);
      });
  },

  [COMPANY_SAVE_REQUEST]({ commit, dispatch }, company) {
    commit(COMPANY_SAVE_REQUEST);
    return axios.patch('/company/', company)
      .then((resp) => {
        commit(COMPANY_SAVE_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(COMPANY_SAVE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
};

const mutations = {
  [COMPANY_REQUEST]: (state) => {
    state.company.loading = !state.company.loading;
  },
  [COMPANY_SUCCESS]: (state, resp) => {
    const errors = {};

    Object.keys(resp).forEach((key) => {
      if (key !== 'lat' && key !== 'lon') {
        errors[key] = !resp[key];
      }
    });

    state.company.needAddData = Object.values(errors).some((value) => value);

    state.company.details = resp;

    state.company.loading = false;
  },
  [COMPANY_SAVE_REQUEST]: (state) => {
    state.company.saveLoading = !state.company.saveLoading;
  },
  [COMPANY_SAVE_SUCCESS]: (state, resp) => {
    state.company.saveLoading = false;
    state.company.details = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
