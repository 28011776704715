<template>
    <component v-bind:is="layout">
      <router-view/>
    </component>
</template>

<script>

import Crm from './layouts/Crm';
import WhiteBlanc from './layouts/WhiteBlanc';

export default {
  name: 'app',
  components: {
    Crm,
    WhiteBlanc,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'Crm';
    },
  },
};
</script>

<style lang="scss">

</style>
